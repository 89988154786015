import React from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { css } from '@emotion/core';
import Gallery from '../components/carousel';
import Layout from '../layouts/layout';
import { contentHeight } from '../utils/config';

const content = css`
  min-height: ${contentHeight}vh;
  text-align: center;
`;

const Index = ({ data: { prismicHomepage } }) => {
  const films = [prismicHomepage.data];
  return (
    <Layout>
      <Container fluid css={content} className="my-4">
        <h1>{prismicHomepage.data.title.text}</h1>
        <Row>
          <Col md={2}></Col>
          <Col md={8}>
              <Gallery films={films} />
          </Col>
          <Col md={2}></Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query IndexQuery {
    prismicHomepage {
      data {
        title {
          text
        }
        thumbnail {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1240) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        video {
          title
          provider_name
          embed_url
        }
      }
    }
  }
`;
