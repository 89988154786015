import React from 'react';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Global, css } from '@emotion/core';
import 'bootstrap/dist/css/bootstrap.css';
import { headerHeight, footerHeight } from '../utils/config';
import yt from '../images/yt.png';
import vm from '../images/vm.png';

const activeStyle = { color: '#000' };

const linkStyle = css`
  color: #454545;
  transition: 0.3s;
  &:hover {
    text-decoration: none;
    color: #000;
  }
`;

const header = css`
  min-height: ${headerHeight}vh;
`;

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const footer = css`
  min-height: ${footerHeight}vh;
`;

const helper = css`
  display: 'inline-block';
  min-height: ${footerHeight}vh;
  vertical-align: 'middle';
`;

const headerTitle = css`
  margin: 0;
  text-transform: uppercase;
`;

const headerSubTitle = css`
  font-size: 12px;
  text-align: center;
`;

const Layout = ({ children }) => {
  return (
    <>
      <Global
        styles={css`
          iframe {
            position: absolute;
            top: 0;
            left: 0;
          }
          iframe {
            width: 100% !important;
            height: 100% !important;
          }
          body {
            color: #000;
            background-color: #fff;
            font-family: 'Raleway',sans-serif;
          }
          .image-gallery {
            transition: all 1s ease;
          }
          canvas {
            margin: 0 auto;
          }
          h1, h2, h3, h4, h5, h6, .card-title, .nav-link {
            text-transform: uppercase;
          }
          .hvr-grow {
            display: inline-block;
            vertical-align: middle;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-property: transform;
            transition-property: transform;
          }
          .hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
          }
          .hvr-shrink {
            display: inline-block;
            vertical-align: middle;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-property: transform;
            transition-property: transform;
          }
          .hvr-shrink:hover, .hvr-shrink:focus, .hvr-shrink:active {
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
          }
        `}
      />
      <Navbar css={header} expand="lg" sticky="top">
        <Container fluid>
          <Navbar.Brand>
            <Link css={linkStyle} to="/">
              <h3 css={headerTitle}>Cody Lum</h3>
              <h5 css={headerSubTitle}>FilmMaker</h5>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Link
                css={linkStyle}
                activeStyle={activeStyle}
                className="nav-link"
                to="/"
              >
                Reel
              </Link>
              <Link
                css={linkStyle}
                activeStyle={activeStyle}
                className="nav-link"
                to="/films/"
              >
                Films
              </Link>
              <Link
                css={linkStyle}
                activeStyle={activeStyle}
                partiallyActive={true}
                className="nav-link"
                to="/screenplays/"
              >
                Screenplays
              </Link>
              <Link
                css={linkStyle}
                activeStyle={activeStyle}
                partiallyActive={true}
                className="nav-link"
                to="/articles/"
              >
                Articles
              </Link>
              <Link
                css={linkStyle}
                activeStyle={activeStyle}
                className="nav-link"
                to="/about/"
              >
                About
              </Link>
              <Link
                css={linkStyle}
                activeStyle={activeStyle}
                className="nav-link"
                to="/contact/"
              >
                Contact
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {children}
      <footer css={footer}>
        <Container fluid css={flexCenter}>
          <span css={helper} />
          <a className="hvr-grow" href="https://www.youtube.com/channel/UCycYHAGjWEaWYumauo6apzA/">
            <img src={yt} alt="Youtube" className="mr-4 mr-sm-5 align-middle" />
          </a>
          <a className="hvr-grow" href="https://vimeo.com/user90116668">
            <img src={vm} alt="Vimeo" className="align-middle" />
          </a>
        </Container>
      </footer>
    </>
  );
};

export default Layout;
