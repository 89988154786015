import React, { useState } from 'react';
import { css } from '@emotion/core';
import Gallery from 'react-image-gallery';
import Img from 'gatsby-image';
import 'react-image-gallery/styles/css/image-gallery.css';

const videoWrapper = css`
  position: relative;
  padding: 28.1% 0;
  height: 0;
`;

const playButton = css`
  z-index: 1;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 60px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 16.5px;
    left: 40px;
    margin: 0 auto;
    border-style: solid;
    border-width: 12.5px 0 12.5px 20px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 1);
  }
  @media (max-width: 768px) {
    height: 40px;
    width: 65px;
    &:after {
      top: 11px;
      left: 27px;
      border-width: 8.5px 0 8.5px 12px;
    }
  }
`;
/*
const itemDescription = css`
  right: 0;
  left: initial !important;
  @media (max-width: 425px) {
    bottom: 20px !important;
  }
`;
*/
const navArrow = css`
  &:hover::before {
    color: gray;
  }
  &:focus {
    outline: none;
  }
`;

const getYoutubeEmbeddedLink = youtubeURL => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = youtubeURL.match(regExp);

  if (match && match[2].length === 11) {
    const id = match[2];
    return `https://www.youtube.com/embed/${id}?feature=oembed&autoplay=1`;
  }
};

const getVimeoEmbeddedLink = vimeoURL => {
  const regExp = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;
  const match = vimeoURL.match(regExp);

  if (match && match[3]) {
    const id = match[3];
    return `https://player.vimeo.com/video/${id}?autoplay=1`;
  }
};

const VideoCarousel = ({ films, showCarouselItems, onVideoChange }) => {
  const [showVideo, setShowVideo] = useState({});
  const images = films.map(film => {
    return {
      title: film.video.title,
      provider: film.video.provider_name,
      url: film.video.embed_url,
      fluid: film.thumbnail.localFile.childImageSharp.fluid,
      description: film.film_description ? film.film_description.text : null,
      thumbnailClass: 'hvr-grow'
    };
  });
  const renderItem = item => {
    return (
      <div className="image-gallery-image">
        {showVideo[item.url] ? (
          <div css={videoWrapper} className="video-wrapper">
            <iframe
              title={item.title}
              width="560"
              height="315"
              src={
                item.provider === 'YouTube'
                  ? getYoutubeEmbeddedLink(item.url)
                  : getVimeoEmbeddedLink(item.url)
              }
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        ) : (
          <div onClick={() => setShowVideo({ [item.url]: true })}>
            <div css={playButton} className="play-button hvr-grow" />
            <Img fluid={item.fluid} />
            {/*item.description && (
              <span className="image-gallery-description" css={itemDescription}>
                {item.description}
              </span>
            )*/}
          </div>
        )}
      </div>
    );
  };
  const renderThumbInner = item => {
    return (
      <div className="image-gallery-thumbnail-inner">
        <Img fluid={item.fluid} />
      </div>
    );
  };
  const renderLeftNav = (onClick, disabled) => {
    return (
      <button
        css={navArrow}
        className="image-gallery-left-nav"
        disabled={disabled}
        onClick={onClick}
      />
    );
  };
  const renderRightNav = (onClick, disabled) => {
    return (
      <button
        css={navArrow}
        className="image-gallery-right-nav"
        disabled={disabled}
        onClick={onClick}
      />
    );
  };
  return (
    <Gallery
      items={images}
      renderItem={renderItem}
      renderThumbInner={renderThumbInner}
      renderRightNav={renderRightNav}
      renderLeftNav={renderLeftNav}
      onSlide={(currentIndex) => {
        setShowVideo(false);
        if(onVideoChange) {
          onVideoChange(currentIndex);
        }
      }}
      showPlayButton={false}
      showFullscreenButton={false}
      showIndex={false}
      showBullets={false}
      showThumbnails={showCarouselItems || false}
      showNav={showCarouselItems || false}
      thumbnailClass="asdfasdfasdf"
    />
  );
};

export default VideoCarousel;
